<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <div style="background-color: #ffffff; " class="d-flex flex-column flex-sm-row popupotp" wrap>
        <div style="background-color: #d9e7fd; height: 100%"
          class="d-flex flex-column justify-center align-center popupotp-left">
          <v-img src="/images/LandingPage/Group1.png" class="ma-10 popup-login-img mt-4"></v-img>

          <v-btn icon="$close" variant="text" color="#14293C" @click="closeOtpDialog"
            class="position-absolute top-0 left-0 mt-md-5 ml-md-5"></v-btn>
        </div>
        <div style="height: 100%" class="d-flex flex-column justify-space-between pa-4 pa-sm-10">
          <div class="d-flex align-center">
            <h1 class="mr-2 pb-2">Join</h1>
            <v-img src="/images/LandingPage/logo.png" max-width="200" max-height="40" contain></v-img>
          </div>
          <div>
            <p style="color: #5f7588">SMS sent to {{ verifyProp }}</p>
          </div>
          <v-form v-model="OtpForm" ref="otpvalidate">
            <div class=" d-flex">
              <v-otp-input v-model="otp" color="#EFF4F9" required></v-otp-input>
            </div>

            <div style="color: #5f7588" class="">
              <p> Resend OTP in {{ countdownTimer }} seconds</p>
            </div>
            <div class="pt-5 d-flex justify-md-space-evenly">
              <v-btn flat v-if="!resendDisabled" @click="resendOtp" rounded="lg" size="large" variant="outlined"
                class="px-md-10 text-capitalize">Resend</v-btn>
              <v-btn @click="verifyotp" flat rounded="lg" color="#3A5267"
                class="px-md-9 ml-2 ml-md-0 text-capitalize text-body-1" size="large" style="color: #ffff">
                Verify OTP
              </v-btn>
            </div>
          </v-form>
          <div class="Otp-termsConditon mt-4 mt-sm-auto align-end text-caption" style="color: grey">
            By signing up, you agree to
            <NuxtLink to="/terms" @click="closeOtpDialog">
              <strong style="color: #14293c">Terms & Conditions</strong>
            </NuxtLink>
            and
            <NuxtLink to="/policy" @click="closeOtpDialog">
              <strong style="color: #14293c"> Privacy Policy</strong>
            </NuxtLink>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<style scoped>
.Otp-termsConditon a {
  text-decoration: none;
}

.popupotp {
  border-radius: 10px 10px 10px 10px;
  height: 560px
}

.popupotp-left {
  border-radius: 10px 0px 0px 10px;
}

.popup-login-img {
  width: 300px;
  height: 276px;
}

@media (max-width: 599.99px) {
  .popup-login-img {
    width: 100%;
    height: 159px;
  }

  .popupotp-left {
    border-radius: 10px 10px 0px 0px;
  }

  .popupotp {
    height: 700px;
  }
}
</style>
<script>
import axios from 'axios';
import mixins from "../../mixins/global";
import { useMainStore } from "../stores/index";
export default {
  mixins: [mixins],
  props: {
    verifyProp: String
  },
  data() {
    return {
      resendDisabled: false,
      countdownTimer: 60,
      store: useMainStore(),
      OtpForm: false,
      otp: "",
      dialog: true,

    };
  },
  methods: {
    closeOtpDialog() {
      this.$emit("closeOtp");
    },
    async resendOtp() {
      this.otp = ''
      try {
        const resendData = {
          mobile_no: this.verifyProp,
        }

        let result = await this.post("resend-otp/dc/v1", resendData, { secure: false });
        this.resendDisabled = true;
        this.startTimer();
        // console.log("otp getting", result);
        if (result) {
          // alert('Please Enter OTP');
          this.store.triggerSnackbar('OTP Send successfully', 'success');
          return
        }

      } catch (err) {
        console.log(err);
      }

    },
    async verifyotp() {

      this.$refs.otpvalidate.validate();
      if (!this.otp) {
        // alert('Please Enter OTP');
        this.store.triggerSnackbar('Please Enter OTP', 'info')
        return
      }
      try {
        const otpdata = {
          otp: this.otp,
          mobileNumber: this.verifyProp
        };

        let result = await this.post("match-otp/dc/v1", otpdata, { secure: false });

        if (result) {
          this.$emit("showLogin1");
          this.store.triggerSnackbar("Verify successfully", 'success');
        }
      }
      catch (err) {
        console.log("this is error", err);
        // alert("OTP Invalid");
        this.store.triggerSnackbar('Somehing went Wrong', 'error')
      }

    },
    startTimer() {
      let timer = setInterval(() => {
        this.countdownTimer--;
        if (this.countdownTimer <= 0) {
          clearInterval(timer);
          this.resendDisabled = false; // Enable resend button
          this.countdownTimer = 60; // Reset timer value
        }
      }, 1000); // Update every second
    },
  },
};
</script>
